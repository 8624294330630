/* Coming Soon Container */
.coming-soon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }
  
  .coming-soon-container.loaded {
    opacity: 1;
  }
  
  /* Content centered inside */
  .coming-soon-content {
    text-align: center;
    color: white;
    transform: translateY(50px);
    transition: transform 2s ease-in-out, opacity 2s ease-in-out;
    opacity: 0;
  }
  
  .coming-soon-container.loaded .coming-soon-content {
    opacity: 1;
    transform: translateY(0);
  }
  
  .logo {
    width: calc(100% - 4rem);
    padding: 2rem 2rem;
  }
  
  .launching-soon-text {
    font-size: 11px;
    letter-spacing: 1em;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
  }
  